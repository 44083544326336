import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import gql from "graphql-tag";
import { CrossReferencesQuery, GetCrossReferenceInput, PagingResultModelOfCrossReferenceModel } from "../gql-types.generated";



// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_CROSSREFERENCES = gql`
    query crossReferences($input: GetCrossReferenceInput!) {
        crossReferences(input: $input) {
            cursor {
                nextPage
                previousPage
            }
            nodes {
                code
                description
                id
                val
                version
            }
        }
    }
`;

export const queryCrossReferences = async(
    client: ApolloClient<NormalizedCacheObject>,
    filters?: GetCrossReferenceInput
): Promise<PagingResultModelOfCrossReferenceModel | undefined> => {
    const {after, before, code, description, id, limit, order, val, version} = filters ?? {};
    const { data, errors } = await client.query<CrossReferencesQuery>({
        variables: {
            input: {
                after, before, code, description, id, limit, order, val, version,
            }
        },
        query: QUERY_CROSSREFERENCES
    });

    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    return data?.crossReferences as PagingResultModelOfCrossReferenceModel;
}