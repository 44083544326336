import { capitalize } from 'lodash';
import { Link } from "@mui/material";
import { GridRowModel } from '@mui/x-data-grid-pro';
import { AddressModel, EmailModel, NoteModel, PhoneModel, ErrorModel, Maybe, UpsertAddressInput, UpsertEmailInput, UpsertNoteInput, UpsertPhoneInput, DocumentStatus, ProcessName, DocumentAcknowledgementStatus } from "../gql-types.generated";
import { CardAvatar } from "../util/SharedStyles";
import { getShortDateString, getTimeString } from "../util/DateTimeUtility";
import { useEffect } from "react";
import { DateTime } from 'luxon';

export const AddressToDisplayString = (address: AddressModel | undefined, includeCountry?: boolean) => {
    let retString = '';
    if (address) {
        if (address.addressLine1) {
            retString += address.addressLine1 + ', ';
        }
        if (address.addressLine2) {
            retString += address.addressLine2 + ', ';
        }
        if (address.addressLine3) {
            retString += address.addressLine3 + ', ';
        }
        if (address.addressLine4) {
            retString += address.addressLine4 + ', ';
        }
        if (address.city) {
            retString += address.city + ', ';
        }
        if (address.state && address.state.stateCode) {
            retString += address.state.stateCode + ', ';
        }
        if (address.postalCode) {
            retString += address.postalCode + ', ';
        }
        if (includeCountry === true && address.countryCode) {
            retString += address.countryCode;
        }
        if (retString.endsWith(', ')) {
            const retLength = retString.length;
            retString = retString.slice(0, retLength - 2);
        }
    }
    return retString;
};

export const getWebsiteLink = (webString: string | undefined) => {
    if (webString) {
        let href = webString;
        if (!href.startsWith('http')) {
            href = 'https://' + webString;
        }
        return (
            <Link href={href} target="_blank" rel="noopener noreferrer">{webString}</Link>
        );
    }
    return undefined;
};

export const getErrorMessage = (mainErrorMessage: string, errors: Maybe<Maybe<ErrorModel>[]> | undefined, withLineBreaks?: boolean, writeToConsole?: boolean) => {
    let errorMessage = '';
    // if there are any specific status errors in the collection, display each of those
    if (errors && errors.length > 0) {
        let lineBreak = withLineBreaks === true ? "\n" : "";
        errors.forEach(error => {
            errorMessage += error?.message + lineBreak;
        });
    } else { //otherwise display the more generalized message
        errorMessage = mainErrorMessage;
    }

    if (writeToConsole === true) console.error(`[EDI Application error]: ${errorMessage}`);

    return errorMessage;
};

export const getFavIconString = (website: string) => {
    if (website) {
        let correctedWebsite = website.indexOf('http') === 0 ? website : 'https://' + website;
        const domain = (new URL(correctedWebsite));
        return 'https://icons.duckduckgo.com/ip3/' + domain.host + '.ico';
        // leaving these other favicon retrieving options here incase the duckduckgo one becomes unavailable for any reason. - Joe
        //return 'http://f1.allesedv.com/48/' + domain.origin;
        //return 'https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=SIZE&size=48&url=' + domain.origin;
        //return 'https://www.google.com/s2/favicons?sz=32&domain_url=' + domain.origin;
    }
};

export const nameStringToInitials = (name: string, maxCharacters: number | undefined) => {
    const brokenDown = name.split(" ");
    let numInitials = brokenDown.length;
    if (maxCharacters && numInitials > maxCharacters) {
        numInitials = maxCharacters;
    }
    let initials = "";
    if (numInitials === 1) {
        initials = brokenDown[0][0] + brokenDown[0][1];
        return initials.toUpperCase();
    }
    for (let i = 0; i < numInitials; ++i) {
        initials += brokenDown[i][0];
    }
    return initials.toUpperCase();
};

export const getAvatarComponent = (website: string | undefined | null, iconLoadingError: boolean, name: string | undefined | null, altString: string, icon: JSX.Element, favIconError: () => void) => {
    if (website && !iconLoadingError) {
        const src = getFavIconString(website);
        return (
            <CardAvatar alt={altString}
                imgProps={{
                    onError: favIconError,
                }}
                src={src}
            />
        );
    } else if (name) {
        return (<CardAvatar alt={altString}>{nameStringToInitials(name, 3)}</CardAvatar>);
    }
    return (<CardAvatar alt={altString}>{icon}</CardAvatar>);
};

export const convertAddressModelToUpsertAddressInput = (address: AddressModel | undefined) => {
    let addressInput = undefined;
    if (address) {
        const { addressLine1, addressLine2, addressLine3, addressLine4, city, countryCode, id, isPrimary, postalCode, stateId } = address;
        addressInput = {
            addressLine1,
            addressLine2,
            addressLine3,
            addressLine4,
            city,
            countryCode,
            id,
            isPrimary,
            postalCode,
            stateId
        } as UpsertAddressInput;
    }
    return (addressInput);
};

export const convertEmailModelToUpsertEmailInput = (emailModel: EmailModel | undefined) => {
    let emailInput = undefined;
    if (emailModel) {
        const { id, description, isPrimary, email } = emailModel;
        emailInput = {
            id,
            description,
            isPrimary,
            email
        } as UpsertEmailInput;
    }
    return (emailInput);
};



export const convertPhoneModelToUpsertPhoneInput = (phoneModel: PhoneModel | undefined) => {
    let phoneInput = undefined;
    if (phoneModel) {
        const { id, description, isPrimary, phoneNumber, phoneTypeCode } = phoneModel;
        phoneInput = {
            id,
            description,
            isPrimary,
            phoneNumber,
            phoneTypeCode
        } as UpsertPhoneInput;
    }
    return (phoneInput);
};

export const convertNoteModelsToUpsertNoteInputs = (noteModels: NoteModel[] | undefined) => {
    if (noteModels && noteModels.length > 0) {
        return noteModels.map((noteModel) => {
            const { id, header, note } = noteModel;
            return {
                id,
                header,
                note
            } as UpsertNoteInput;
        }) as UpsertNoteInput[];
    } else {
        return undefined;
    }
};

export const calculateAddTransactionDialogMinWidth = (gridRows: GridRowModel[], isSingleSelectGrid?: boolean) => {
    const defaultAddDialogMinWidth = 300;
    const defaultValueLength = 25;
    const multiSelectWidth = isSingleSelectGrid === true ? 0 : 60;

    let addDialogMinWidth = defaultAddDialogMinWidth;
    let longestValueLength = defaultValueLength;
    let longestValue = '';
    if (gridRows && gridRows.length > 0) {
        gridRows.forEach(row => {
            let nameDescription = row.type;
            if (row.description) {
                nameDescription = `${row.type} - ${row.description}`;
            }
            let cellLength = nameDescription.length;
            if (cellLength > longestValueLength) {
                longestValueLength = cellLength;
                longestValue = nameDescription;
            }
        });
    }
    if (longestValue) {
        // temporality add element to dom to get its width
        const p = document.createElement("p");
        p.innerText = longestValue;
        // put the element out of sight
        p.style.position = "absolute";
        p.style.top = "-1000px";
        document.body.appendChild(p);
        // capture the width
        const {offsetWidth: calcWidth} = p; 
        // got the width of the value, so now remove the temp element from the DOM
        document.body.removeChild(p);
        
        // increase overall width if longest value causes larger width than default
        // to prevent wrapping if possible, since the dialog doesn't have a set width
        // and datagrid needs a set width to fill
        let newMinWidth = calcWidth + multiSelectWidth;
        if (newMinWidth > addDialogMinWidth) {
            addDialogMinWidth = newMinWidth;
        }
    }
    
    return addDialogMinWidth;
};

export const getFileDataString = (base64: boolean, mimeType?: string) => {
    if (mimeType) {
        let dataString = 'data:' + mimeType + ';';
        if (base64) {
            dataString = dataString + 'base64';
        }
        return dataString;
    }
};

export const isJson = (value: string) => {
    try {
       JSON.parse(value);
    } catch (e){
       //Error
       //JSON is not okay
       return false;
    }
  
   return true;
 }

export const downloadBlobAsFile = (blob: Blob, fileName: string) => {
    const fileURL = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = fileURL;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
        document.body.removeChild(a);
        URL.revokeObjectURL(fileURL);
    }, 40000); // 40 seconds is what FileSaver uses.  revokeObjectURL justs marks it for GC, not manually cleaning itself.
}

export const openBlobAsFileInNewTab = (blob: Blob, fileName: string) => {
    const fileURL = URL.createObjectURL(blob);
    window.open(fileURL, fileName);
    setTimeout(function () {
        URL.revokeObjectURL(fileURL);
    }, 40000); // 40 seconds is what FileSaver uses.  revokeObjectURL justs marks it for GC, not manually cleaning itself.
};

export const downloadDocument = (documentString?: string, fileName?: string, mimeType?: string, base64?: boolean) => {
    if (documentString !== undefined && documentString !== null && fileName) {
        // get mimetype prefix with base64 appended if needed
        let isBase64String = base64 === true ? true : false;
        let fileData = getFileDataString(isBase64String, mimeType) ?? "";
        
        // after getting appropriate file prefix, insert with documentString
        // and simulate an anchor tag click to download the file
        const linkSource = `${fileData},${documentString}`;
        let downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }
};

// common function to grab the first 250 characters from a value 
// to display a preview in the grid
export const getDataGridCellValuePreview = (cellValue: string) => {
    let ret = cellValue;
    if (ret) {
        const maxChar = 250;
        ret = ret.replace(/<[^>]*>/g, ' ');
        ret = ret.replace(/\s{2,}/g, ' ');
        ret = ret.trim();
        if (ret.length > maxChar) {
            ret = ret.substring(0, maxChar);
            ret += '...';
        }
    }

    return ret;
};

export const getLastModifiedString = (lastModifiedBy?: string | null, lastModifiedDT?: string | null) => {
    if (!lastModifiedBy && !lastModifiedDT) {
        return null;
    }
    let ret = "";
    if (lastModifiedBy) {
        ret += lastModifiedBy + ' - ';
    }
    if (lastModifiedDT) {
        ret += getShortDateString(lastModifiedDT) + ' ' + getTimeString(lastModifiedDT);
    }
    return ret;
};


/**
 * Should be used in pages that have a route.  Will Prepend "Aptean EDI - " to the string passed in, or just "Aptean EDI" if passed nothing
 * @param title string - Title to show
 */
export function useTitle(title?: string) {
    let pageTitle = "Aptean EDI";
    if (title && title.length > 0 && title !== "Aptean EDI") {
        pageTitle += ' - ' + title;
    }
    useEffect(() => {
        const prevTitle = document.title
        if (prevTitle !== pageTitle) {
            document.title = pageTitle
            return () => {
                document.title = prevTitle
            }
        }
    })
};

export const capitalizeWithUnderscoreRemoval = (value: string, joiningCharacter?: string) => {
    // scenarios where could have for example, POWER_SHELL value and want to return PowerShell
    const valueParts = value?.split('_');
    let capitalizedParts: string[] = [];
    valueParts?.forEach(p => capitalizedParts.push(capitalize(p)));
    const displayValue = capitalizedParts.join(joiningCharacter ?? '');
    return displayValue;
}

export const getBase64 = async (file: Blob): Promise<string | undefined> => {
    var reader = new FileReader();
    reader.readAsDataURL(file as Blob);

    return new Promise((resolve, reject) => {
        reader.onload = () => resolve(reader.result as any);
        reader.onerror = (error) => reject(error);
    });
};

export interface PremadeEventLogFilterSet {
    filterClient?: string;
    filterIO?: string;
    filterDocType?: string;
    filterPartner?: string;
    filterPONum?: string;
    filterDocNum?: string;
    filterStatusVal?: DocumentStatus;
    filterProcess?: ProcessName;
    filterDocDateFrom?: DateTime;
    filterDocDateTo?: DateTime;
    filterFileName?: string;
}

export interface PremadeDocumentArchiveFilterSet {
    filterClient?: string;
    filterIO?: string;
    filterDocType?: string;
    filterPartner?: string;
    filterControlNum?: string;
    filterFunctionalGroupControlNum?: string;
    filterTransactionControlNum?: string;
    filterPurchaseOrderNum?: string;
    filterErpOrderNum?: string;
    filterDocumentNum?: string;
    filterAcknowledgmentStatuses?: DocumentAcknowledgementStatus[];
    filterFileNameVal?: string;
    filterCreatedDateFrom?: DateTime;
    filterCreatedDateTo?: DateTime;
    filterWasNotificationSent?: boolean;
    filterIsNotificationRequired?: boolean;
}