import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpsertClientPayload, UpsertClientInput } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_CLIENT = gql`
    mutation upsertClient($input: UpsertClientInput!){
        upsertClient(input: $input){
            message
            errors {
                code
                message
            }
            result
            client {
                id
            }
        }
    }
`;

export const mutationUpsertClient = async (
    client: ApolloClient<NormalizedCacheObject>,
    args: {
        clientInput: UpsertClientInput
    }
): Promise<UpsertClientPayload | undefined> => {
    const {
        addresses,
        businessAreaId,
        isaQualifierCode,
        clientCode,
        emails,
        erpProductId,
        id,
        internalClientCode,
        name,
        notes,
        phones,
        statusCode,
        tenantId,
        website,
        billingId,
        billingStartDate,
        bulkNotificationTime,
        errorFormatterId, 
        missingAcknowledgementNotificationMode,
        localeCode,
        defaultCurrencyCode,
        erpDocumentFormat
    } = args.clientInput;

    const { data, errors } = await client.mutate<{ upsertClient: UpsertClientPayload }>({
        variables: {
            input: {
                addresses,
                businessAreaId,
                isaQualifierCode,
                clientCode,
                emails,
                erpProductId,
                id,
                internalClientCode,
                name,
                notes,
                phones,
                statusCode,
                tenantId,
                website,
                billingId,
                billingStartDate,
                bulkNotificationTime,
                errorFormatterId,
                missingAcknowledgementNotificationMode,
                localeCode,
                defaultCurrencyCode,
                erpDocumentFormat
            }
        },
        mutation: MUTATION_UPSERT_CLIENT
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred during the client contact save.');
    }
    if (data && data.upsertClient) {
        return data.upsertClient;
    }
    return undefined;
};