import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { PagingResultModelOfDocumentArchiveModel, GetDocumentArchivesInput, DocumentArchiveListQuery, DocumentArchiveModel } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_DOCUMENT_ARCHIVE_BY_ID = gql`
    query documentArchiveById($input: GetDocumentArchivesInput!) {
        documentArchives (input: $input) {
            cursor {
                nextPage
                previousPage
              }
            nodes {
                id
                acknowledgementRequestCount
                acknowledgementRequestTime
                acknowledgementStatus
                bulkNotify
                client {
                    id
                    name
                }
                controlNumber
                functionalGroupControlNumber
                transactionControlNumber
                createdTime
                direction
                documentDate
                documentType
                ediDocument
                ediDocumentResentCount
                ediDocumentResentTime
                ediXml
                erpOrderNumber
                fileName
                originalFileName
                internalClientCode
                internalPartnerCode
                documentNumber
                isAbandoned
                notifiedTime
                partner {
                    id
                    name
                }
                purchaseOrder
                receiverId
                senderId
                wasNotificationSent
                currencyCode
            }
        }
    }
`;

export const queryDocumentArchiveById = async (
    client: ApolloClient<NormalizedCacheObject>,
    id: string
): Promise<DocumentArchiveModel | undefined> => {
    const { data, errors } = await client.query<DocumentArchiveListQuery>({
        variables: {
            input: {
                id
            }
        },
        query: QUERY_DOCUMENT_ARCHIVE_BY_ID,
    });

    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }
    if (data?.documentArchives && data?.documentArchives.nodes && data?.documentArchives.nodes?.length > 0) {
        return data?.documentArchives?.nodes[0] as DocumentArchiveModel;
    }
    
    return undefined;
}