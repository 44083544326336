import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { DateTime } from 'luxon';
import { Button, CircularProgress, Dialog, DialogTitle, Grid, Link, Stack, Typography, Tooltip, styled } from "@mui/material";
import { DocumentAcknowledgementStatus, RequestResult } from "../../gql-types.generated";
import DialogCloseButton from "./DialogCloseButton";
import { PaddedDialogContent } from "../../util/SharedStyles";
import { clearError, fetchDocumentArchiveByIdSuccess, selectDocumentArchiveById, captureGetDocumentHtmlFileStatus, captureResendDocumentStatus, selectGetDocumentHtmlFileStatus, selectResendDocumentStatus, selectGetDocumentAcknowledgementFileStatus, captureGetDocumentAcknowledgementFileStatus } from "../../features/DocumentArchives/DocumentArchivesSlice";
import { fetchDocumentArchiveById, getDocumentAcknowledgementFile, getDocumentHtmlFile, resendDocument } from "../../features/DocumentArchives/DocumentArchivesActions";
import ErrorMessage from "../ErrorMessage";
import { capitalizeWithUnderscoreRemoval, downloadDocument} from "../../util/Common";
import { ToastSeverity } from "../../util/Constants";
import { getFormattedDateTimeString } from "../../util/DateTimeUtility";
import { setToastConfig } from "../../features/EDIContainer/EDIContainerSlice";
import ConfirmationPrompt from "../ConfirmationPrompt";
import theme from "../../Theme";
import { DownloadSplitButton } from "../buttons/DownloadSplitButton";

const TitleBar = styled(DialogTitle)((props) => ({
    height: '56px',
    padding: '4px 24px 0px 24px !important'
}));

const TitleGrid = styled(Grid)((props) => ({
    height: '100%',
}));

const ToolbarButton = styled(Button)`
     margin-right: 4px;
`;

interface fieldProps {
    disabled?: boolean;
}
const Field = styled(Typography)<fieldProps>((props) => ({
    color: props.disabled ? theme.palette.grayscale.light : 'unset',
}));
Field.defaultProps = {
    disabled: false,
};

interface DialogProps {
    isOpen: boolean;
    documentArchiveRecordId?: string;
    userCanEdit: boolean;
    onClose: () => void;
    onAcknowledge: (rowId: string) => void;
    onAbandon: (rowId: string) => void;
    onResendEmail: (rowId: string) => void;
    onClientNameClick: (clientId: string | undefined) => void;
    onPartnerNameClick: (partnerId: string | undefined) => void;
    error?: Error | undefined;
}

const DocumentArchiveDetailsDialog: React.FC<DialogProps> = props => {
    const {isOpen, documentArchiveRecordId, userCanEdit, onClose, onAcknowledge, onAbandon, onResendEmail, onClientNameClick, onPartnerNameClick, error} = props;
    const dispatch = useAppDispatch();
    const [isProcessing, setIsProcessing] = useState(false);
    const [openResendDocConfirm, setOpenResendDocConfirm] = useState(false);
    const [resendDocumentErrorMessage, setResendDocumentErrorMessage] = useState('');
    const [isResendingDocument, setIsResendingDocument] = useState(false);

    const documentArchive = useAppSelector(selectDocumentArchiveById);
    const getHtmlFileStatus = useAppSelector(selectGetDocumentHtmlFileStatus);
    const resendDocumentStatus = useAppSelector(selectResendDocumentStatus);
    const getAcknowledgementFileStatus = useAppSelector(selectGetDocumentAcknowledgementFileStatus);

    const isOutbound = documentArchive?.direction as string === 'O';

    useEffect(() => {
        if (documentArchiveRecordId) {
            dispatch(fetchDocumentArchiveById(documentArchiveRecordId));
        }
    },[documentArchiveRecordId]);

    useEffect(() => {
        if (!isOpen) {
            setIsProcessing(false);
            dispatch(fetchDocumentArchiveByIdSuccess());
        }
    },[isOpen]);

    useEffect(() => {
        if (getHtmlFileStatus?.result === RequestResult.Success) {
            // get the base64 file from the data and call download
            var file = getHtmlFileStatus.data as string;
            downloadDocument(file, "htmlFile.html", "text/html", true);
        } else if (getHtmlFileStatus?.result === RequestResult.Fail) {
            dispatch(setToastConfig({
                message: getHtmlFileStatus.message as string,
                severity: ToastSeverity.Error
            }));
        }

        // set processing flag back to false
        setIsProcessing(false);
        // remove get status
        dispatch(captureGetDocumentHtmlFileStatus());
    }, [getHtmlFileStatus?.result]);

    useEffect(() => {
        if (getAcknowledgementFileStatus?.result === RequestResult.Success) {
            // get the base64 file from the data and call download
            var file = getAcknowledgementFileStatus.data as string;
            downloadDocument(file, "997File.txt", "text/plain", true);
        } else if (getAcknowledgementFileStatus?.result === RequestResult.Fail) {
            dispatch(setToastConfig({
                message: getAcknowledgementFileStatus.message as string,
                severity: ToastSeverity.Error
            }));
        }

        // set processing flag back to false
        setIsProcessing(false);
        // remove get status
        dispatch(captureGetDocumentAcknowledgementFileStatus());
    }, [getAcknowledgementFileStatus?.result]);


    useEffect(() => {
        if (resendDocumentStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: resendDocumentStatus.message as string,
                severity: ToastSeverity.Success
            }));
            // remove resend status
            dispatch(captureResendDocumentStatus());
            // close the confirmation dialog and refresh
            onResendDocumentDialogClose();
            refreshDialog();
        } else if (resendDocumentStatus?.result === RequestResult.Fail) {
            setResendDocumentErrorMessage(resendDocumentStatus.message as string);
            setIsResendingDocument(false);
        }

        // set processing flag back to false
        setIsProcessing(false);
        
    }, [resendDocumentStatus?.result]);

    let header = "Document Archive Details";

    const refreshDialog = () => {
        dispatch(fetchDocumentArchiveByIdSuccess());
        dispatch(fetchDocumentArchiveById(documentArchive?.id));
    };

    const getDisplayContent = () => {
        if (documentArchive) {
            return getDisplayFields();
        } else if (error) {
            return <ErrorMessage title='Unable to load the Document Archives' error={error}></ErrorMessage>
        } else {
            return <CircularProgress aria-label={'progress spinner'} key={'logs-spinner'} size={42} sx={{ zIndex: 1 }} />
        }
    };

    const acknowledgeHandler = () => {
        onAcknowledge(documentArchive?.id);
    };

    const abandonHandler = () => {
        onAbandon(documentArchive?.id);
    };

    const resendEmailHandler = () => {
        onResendEmail(documentArchive?.id);
    };

    const resendDocumentHandler = () => {
        if (documentArchive?.id && documentArchive?.internalClientCode) {
            setOpenResendDocConfirm(true);
        }
    };

    const downloadDocumentHandler = () => {
        if (documentArchive?.fileName && documentArchive?.ediDocument) {
            // expecting ediDocument to be in base64 from backend
            downloadDocument(documentArchive?.ediDocument, documentArchive?.fileName, "text/plain", true);
        }
    };

    const downloadXmlHandler = () => {
        if (documentArchive?.ediXml) {
            // expecting ediXml to be in base64 from backend
            downloadDocument(documentArchive?.ediXml, "ediXML.xml", "text/xml", true);
        }
    };

    const downloadHtmlHandler = () => {
        if (documentArchive?.id) {
            setIsProcessing(true);
            dispatch(getDocumentHtmlFile(documentArchive?.id));
        }
    };

    const downloadAcknowledgementHandler = () => {
        if (documentArchive?.id) {
            setIsProcessing(true);
            dispatch(getDocumentAcknowledgementFile(documentArchive?.id));
        }
    };

    const onDialogClose = () => {
        // Clear error on close.
        dispatch(clearError());
    };

    const onResendDocumentDialogClose = () => {
        setOpenResendDocConfirm(false);
        onDialogClose();
        dispatch(captureResendDocumentStatus());
        setIsResendingDocument(false);
        setResendDocumentErrorMessage('');
    };

    const onResendDocumentConfirm = () => {
        // resend document for this archive
        setIsProcessing(true);
        setIsResendingDocument(true);
        dispatch(resendDocument(documentArchive?.id, documentArchive?.internalClientCode as string));
    };

    const getResendDocumentDialogMessage = () => {
        let message = "Are you sure you want to resend the document for this archive?";
        let resendCount = documentArchive?.ediDocumentResentCount ?? 0;
        if (resendCount > 0) {
          let resendDate = getFormattedDateTimeString(documentArchive?.ediDocumentResentTime, { format: DateTime.DATETIME_SHORT });
          message =  `${message}\nLast Resent: ${resendDate}`;
        }
        return message;
    };

    const getAcknowledgeButton = () => {
        if (documentArchive) {
            let disable = documentArchive.acknowledgementStatus === DocumentAcknowledgementStatus.Accepted || documentArchive.isAbandoned;
            let button = (
                <ToolbarButton
                    title={"Manually Acknowledge"}
                    aria-label="Acknowledge"
                    size="small"
                    onClick={acknowledgeHandler}
                    color='primary'
                    disabled={disable}
                >
                    Acknowledge
                </ToolbarButton>
            );
            if (disable) {
                return (
                    <Tooltip title="Can not Acknowledge, status is Accepted or Abandoned">
                        <span>
                            {button}
                        </span>
                    </Tooltip>
                )
            } else {
                return button;
            }
        }
    }

    const getDlDocumentButton = () => {
        if (documentArchive) {
            let disable = true;
            if (documentArchive.fileName && documentArchive.ediDocument) {
                disable = false;
            }
            let button = (
                <ToolbarButton
                    title="Download EDI Document"
                    aria-label="Download EDI"
                    size="small"
                    onClick={downloadDocumentHandler}
                    color='primary'
                    disabled={disable}
                >
                    Download EDI
                </ToolbarButton>
            );
            if (disable) {
                return (
                    <Tooltip title="Document not present">
                        <span>
                            {button}
                        </span>
                    </Tooltip>
                )
            } else {
                return button;
            }
        }
    }

    const getDlXmlButton = () => {
        if (documentArchive) {
            let disable = true;
            if (documentArchive.ediXml) {
                disable = false;
            }
            let button = (
                <ToolbarButton
                    title="Download Raw EDI XML"
                    aria-label="Download Raw EDI XML"
                    size="small"
                    onClick={downloadXmlHandler}
                    color='primary'
                    disabled={disable}
                >
                    Download XML
                </ToolbarButton>
            )
            if (disable) {
                return (
                    <Tooltip title="EDI XML not present">
                        <span>
                            {button}
                        </span>
                    </Tooltip>
                )
            } else {
                return button;
            }
        }
    };

    const getActionsToolBar = () => {
        if (documentArchive) {
            // Acknowledge, Delete, Resend, Download ediDocument, download ediXml
            return (
                <Stack direction="row" spacing={2} justifyContent="flex-end">
                    { userCanEdit &&    
                        getAcknowledgeButton()
                    }
                    { userCanEdit &&  
                    <ToolbarButton
                        title="Abandon"
                        aria-label="Abandon"
                        size="small"
                        disabled={documentArchive.isAbandoned}
                        onClick={abandonHandler}
                        color='error'
                    >
                        Abandon
                    </ToolbarButton>
                    }
                    { userCanEdit &&
                    <ToolbarButton
                        title="Resend Document"
                        aria-label="Resend Document"
                        size="small"
                        disabled={documentArchive.isAbandoned}
                        onClick={resendDocumentHandler}
                        color={documentArchive?.ediDocumentResentCount as number > 0 ? 'warning' : 'primary'}
                    >
                        Resend Document
                    </ToolbarButton>
                    }
                    <ToolbarButton
                        title="Resend Email"
                        aria-label="Resend Email"
                        size="small"
                        disabled={documentArchive.isAbandoned}
                        onClick={resendEmailHandler}
                        color='primary'
                    >
                        Resend Email
                    </ToolbarButton>
                    <DownloadSplitButton
                        onDownloadHtmlClick={downloadHtmlHandler}
                        onDownloadRawEdiClick={downloadDocumentHandler}
                        onDownloadXmlClick={downloadXmlHandler}
                        onDownloadAcknowledgementClick={documentArchive.acknowledgementStatus !== DocumentAcknowledgementStatus.Unacknowledged ? downloadAcknowledgementHandler : undefined}
                    />
                </Stack>
            )
        }
    }

    const getDisplayFields = () => {
        let isAbandoned = documentArchive?.isAbandoned;
        return(
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Typography variant='caption' aria-label='client'>Client</Typography>
                    <div>
                        <Link
                            component="button"
                            variant="body1"
                            color={isAbandoned ? theme.palette.warning.light : theme.palette.primary.main}
                            onClick={() => {
                                onClientNameClick(documentArchive?.client?.id);
                            }}
                        >
                            {documentArchive?.client?.name}
                        </Link>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='caption' aria-label='document-type'>Document Type</Typography>
                    <Field variant='body1' disabled={isAbandoned}>{documentArchive?.documentType}</Field>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='caption' aria-label='direction'>Direction</Typography>
                    <Field variant='body1' disabled={isAbandoned}>{documentArchive?.direction}</Field>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='caption' aria-label='control-number'>Control #</Typography>
                    <Field variant='body1' disabled={isAbandoned} >{documentArchive?.controlNumber}</Field>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='caption' aria-label='functional group control number'>Functional Group Control #</Typography>
                    <Field variant='body1' disabled={isAbandoned} >{documentArchive?.functionalGroupControlNumber}</Field>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='caption' aria-label='transaction control number'>Transaction Control #</Typography>
                    <Field variant='body1' disabled={isAbandoned} >{documentArchive?.transactionControlNumber}</Field>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='caption' aria-label='purchase-order-number'>Purchase Order #</Typography>
                    <Field variant='body1' disabled={isAbandoned} >{documentArchive?.purchaseOrder}</Field>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='caption' aria-label='purchase-order-number'>ERP Order #</Typography>
                    <Field variant='body1' disabled={isAbandoned} >{documentArchive?.erpOrderNumber}</Field>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='caption' color={isAbandoned ? theme.palette.warning.light : undefined} aria-label='is abandoned'>Is Abandoned</Typography>
                    <Typography variant='body1' color={isAbandoned ? theme.palette.warning.light : undefined} >{documentArchive?.isAbandoned.toString()}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='caption' aria-label='partner'>Partner</Typography>
                    <div>
                        <Link
                            component="button"
                            variant="body1"
                            color={isAbandoned ? theme.palette.warning.light : theme.palette.primary.main}
                            onClick={() => {
                                onPartnerNameClick(documentArchive?.partner?.id);
                            }}
                        >
                            {documentArchive?.partner?.name}
                        </Link>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='caption' aria-label='document-date'>Document Date</Typography>
                    <Field variant='body1' disabled={isAbandoned} >{documentArchive?.documentDate ? getFormattedDateTimeString(documentArchive?.documentDate) : ""}</Field>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='caption' aria-label='created-date'>Created Date</Typography>
                    <Field variant='body1' disabled={isAbandoned} >{documentArchive?.createdTime ? getFormattedDateTimeString(documentArchive?.createdTime) : ""}</Field>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='caption' aria-label='bulk-notify'>Bulk Notify</Typography>
                    <Field variant='body1' disabled={isAbandoned} >{documentArchive?.bulkNotify?.toString()}</Field>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='caption' aria-label='was-notification-sent'>Notification Sent</Typography>
                    <Field variant='body1' disabled={isAbandoned} >{documentArchive?.wasNotificationSent?.toString()}</Field>
                </Grid>   
                <Grid item xs={4}>
                    <Typography variant='caption' aria-label='notified-time'>Notified Time</Typography>
                    <Field variant='body1' disabled={isAbandoned} >{documentArchive?.notifiedTime ? getFormattedDateTimeString(documentArchive?.notifiedTime) : ''}</Field>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='caption' aria-label='file-name'>File Name</Typography>
                    <Field variant='body1' disabled={isAbandoned} noWrap >{documentArchive?.fileName}</Field>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='caption' aria-label='file-name'>Original File Name</Typography>
                    <Field variant='body1' disabled={isAbandoned} noWrap >{documentArchive?.originalFileName}</Field>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='caption' aria-label='invoice'>Document Number</Typography>
                    <Field variant='body1' disabled={isAbandoned} >{documentArchive?.documentNumber}</Field>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='caption' aria-label='acknowledgement-status'>Acknowledgement Status</Typography>
                    <Field variant='body1' disabled={isAbandoned} >{capitalizeWithUnderscoreRemoval(documentArchive?.acknowledgementStatus as string)}</Field>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='caption' aria-label='acknowledgement-request-count'>Acknowledgement Request Count</Typography>
                    <Field variant='body1' disabled={isAbandoned} >{documentArchive?.acknowledgementRequestCount}</Field>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='caption' aria-label='acknowledgement-request-time'>Acknowledgement Request Time</Typography>
                    <Field variant='body1' disabled={isAbandoned} >{documentArchive?.acknowledgementRequestTime}</Field>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='caption' aria-label='internal-client-code'>Internal Client Code</Typography>
                    <Field variant='body1' disabled={isAbandoned} >{documentArchive?.internalClientCode}</Field>
                </Grid>
                { isOutbound && 
                <Grid item xs={4}>
                    <Typography variant='caption' aria-label='edi-document-resent-count'>Document Resent Count</Typography>
                    <Field variant='body1' disabled={isAbandoned} >{documentArchive?.ediDocumentResentCount}</Field>
                </Grid>
                }
                { isOutbound &&
                <Grid item xs={4}>
                    <Typography variant='caption' aria-label='notified-time'>Document Last Resent Date</Typography>
                    <Field variant='body1' disabled={isAbandoned} >{documentArchive?.ediDocumentResentTime ? getFormattedDateTimeString(documentArchive?.ediDocumentResentTime) : ''}</Field>
                </Grid>
                }
                <Grid item xs={4}>
                    <Typography variant='caption' aria-label='internal-partner-code'>Internal Partner Code</Typography>
                    <Field variant='body1' disabled={isAbandoned} >{documentArchive?.internalPartnerCode}</Field>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='caption' aria-label='file-name'>Currency</Typography>
                    <Field variant='body1' disabled={isAbandoned} noWrap >{documentArchive?.currencyCode}</Field>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='caption' aria-label='receiver-id'>Receiver</Typography>
                    <Field variant='body1' disabled={isAbandoned} >{documentArchive?.receiverId}</Field>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='caption' aria-label='sender-id'>Sender</Typography>
                    <Field variant='body1' disabled={isAbandoned} >{documentArchive?.senderId}</Field>
                </Grid>
                             
            </Grid>

        );
    };

    return <Dialog
        aria-label="document-archive-details-dialog"
        maxWidth='md'
        open={isOpen}
        scroll="paper"
    >
        <TitleBar id='dialog-title'>
            <TitleGrid container item direction="row" justifyContent="space-between" alignItems="center" xs={11}>
                <Typography variant="body1">{header}</Typography>
            </TitleGrid>
            <DialogCloseButton onClick={onClose}></DialogCloseButton>
        </TitleBar>
        <PaddedDialogContent dividers padding="10px 30px 30px 30px">
            {getActionsToolBar()}
            {getDisplayContent()}
        </PaddedDialogContent>
        {(isProcessing) &&
            <CircularProgress
                aria-label={'progress spinner'}
                size={48}
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-24px',
                    marginLeft: '-24px',
                }}
            />
        }
        <Dialog
                open={openResendDocConfirm}>
                <ConfirmationPrompt
                    heading="Resend Document"
                    message={getResendDocumentDialogMessage()}
                    handleConfirm={onResendDocumentConfirm}
                    handleReject={onResendDocumentDialogClose}
                    confirmButtonText="Resend"
                    confirmButtonColor={documentArchive?.ediDocumentResentCount as number > 0 ? 'warning' : 'primary'}
                    isBusy={isResendingDocument}
                    errorMessage={resendDocumentErrorMessage}
                />
        </Dialog>
    </Dialog>
}

export default DocumentArchiveDetailsDialog;