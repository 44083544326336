import { useEffect, useMemo, useState } from 'react';
import { styled, Button, Stack, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from '../app/hooks';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from "@mui/icons-material/Download";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import { GridColumns, GridRenderCellParams, GridRowModel } from '@mui/x-data-grid-pro';

import { EdiXmlConfigModel, RequestResult, UpsertEdiXmlConfigInput, UserRole } from "../gql-types.generated";
import { TabContainer, TabContent, TabDataGridNoRowHover, TabToolbar } from "../util/SharedStyles";
import { viewerCanEdit } from '../util/ViewerUtility';
import { downloadDocument } from '../util/Common';
import { getShortDateString, getTimeString } from '../util/DateTimeUtility';
import NoRecordsMessage from './NoRecordsMessage';
import GridCellDualVert from './listItems/GridCellDualVert';
import AddEdiXmlConfigDialog from './dialogs/AddEdiXmlConfigDialog';
import DeleteDialog from './dialogs/DeleteDialog';
import { captureDeleteEdiXmlConfigStatus, captureUpsertEdiXmlConfigSuccess, clearError, selectDeleteEdiXmlConfigStatus, selectError, selectUpsertEdiXmlConfigStatus } from '../features/ClientDetails/ClientDetailsSlice';
import { deleteEdiXmlConfig, upsertEdiXmlConfig } from '../features/ClientDetails/ClientDetailsActions';
import { setToastConfig } from '../features/EDIContainer/EDIContainerSlice';
import { ToastSeverity } from '../util/Constants';

const StyledButton = styled(Button)((props) => ({
    maxHeight: '38px',
}));

interface ConfigurationFileProps {
    file?: EdiXmlConfigModel | undefined;
    clientId: string;
    viewerRole: UserRole | undefined;
    refreshClientData: () => void;
}

const ConfigurationFile: React.FC<ConfigurationFileProps> = (props) => {
    const { file, clientId, viewerRole, refreshClientData } = props;
    const dispatch = useAppDispatch();
    const [upsertFileText, setUpsertFileText] = useState("ADD CONFIG FILE");
    const [upsertFileVariant, setUpsertFileVariant] = useState<"text" | "contained">("contained");
    const [fileRows, setFileRows] = useState<GridRowModel[] | undefined>(undefined);
    const [isAddOpen, setIsAddOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [addEditIcon, setAddEditIcon] = useState(<AddCircleIcon />);

    const canEdit = viewerCanEdit(viewerRole);

    const error = useAppSelector(selectError);
    const upsertStatus = useAppSelector(selectUpsertEdiXmlConfigStatus);
    const deleteStatus = useAppSelector(selectDeleteEdiXmlConfigStatus);

    const configurationColumns = useMemo<GridColumns<GridRowModel>>(
        () => [
            {
                headerName: 'NAME',
                field: 'fileName',
                minWidth: 200,
            },
            {
                headerName: 'DESCRIPTION',
                field: 'description',
                minWidth: 200,
                flex: 1
            },
            {
                headerName: 'DATE CREATED',
                field: 'createdTime',
                type: 'dateTime',
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    let dateValue = getShortDateString(value);
                    let timeValue = getTimeString(value, { includeSeconds: true });
                    return (
                        <GridCellDualVert header={dateValue} sub={timeValue} boldHeader />
                    )
                },
                minWidth: 120,
            },
            {
                headerName: 'CREATED BY',
                field: 'createdBy',
                minWidth: 200,
            },
        ], [],
    );

    const getConfigFileRowArray = () => {
        if (file) {
            const { description, fileName, id, createdByName, createdTime } = file;
            return [
                {
                    _raw: file,
                    description,
                    fileName,
                    id,
                    createdBy: createdByName,
                    createdTime,
                } as GridRowModel
            ] as GridRowModel[];
        } else {
            return [];
        }
    }

    const onDownloadClick = () => {
        if (file && file.documentString !== undefined && file.documentString !== null && file.fileName) {
            downloadDocument(file.documentString, file.fileName, "application/xml", true);
        }
    }

    useEffect(() => {
        setUpsertFileText(file ? "EDIT" : "ADD CONFIG FILE");
        setUpsertFileVariant(file ? "text" : "contained");
        setAddEditIcon(file ? <EditIcon /> : <AddCircleIcon />);
        if (file) {
            setFileRows(getConfigFileRowArray());
        }
    }, [file])

    useEffect(() => {
        if (upsertStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: upsertStatus.message as string,
                severity: ToastSeverity.Success
            }));
            dispatch(captureUpsertEdiXmlConfigSuccess());
            onAddDialogClose();
            refreshClientData();
        }

    }, [upsertStatus?.result]);

    useEffect(() => {
        if (deleteStatus?.result === RequestResult.Success) {
            onDeleteDialogClose();
            dispatch(setToastConfig({
                message: deleteStatus.message as string,
                severity: ToastSeverity.Success
            }));
            refreshClientData();
        } else if (deleteStatus?.result === RequestResult.Fail) {
            setDeleteErrorMessage(deleteStatus.message as string);
        }
    }, [deleteStatus?.result])

    const onAddFile = () => {
        setIsAddOpen(true);
    }

    const onDeleteFile = () => {
        dispatch(captureDeleteEdiXmlConfigStatus());
        setDeleteErrorMessage('');
        setOpenDelete(true);
    }

    const handleDeleteFileConfirm = () => {
        if (file && file.id) {
            dispatch(deleteEdiXmlConfig(file.id));
        }
    }

    const onAddDialogClose = () => {
        setIsAddOpen(false);
    }

    const onDeleteDialogClose = () => {
        setOpenDelete(false);
        dispatch(clearError());
        dispatch(captureDeleteEdiXmlConfigStatus());
        setDeleteErrorMessage('');
    }

    const onAddDialogSave = (upsertData: UpsertEdiXmlConfigInput) => {
        if (upsertData) {
            dispatch(upsertEdiXmlConfig(upsertData));
        }
        setIsAddOpen(false);
    }

    const getFileContent = () => {
        if (file) {
            return <TabDataGridNoRowHover
                loading={false}
                headerHeight={36}
                rowHeight={52}
                aria-label="Configuration File"
                hideFooter
                disableColumnMenu
                disableColumnFilter
                disableSelectionOnClick
                rows={fileRows ?? []}
                columns={configurationColumns}
            />
        } else {
            let noRecords = (canEdit ?
                <NoRecordsMessage topMargin={4} actionButtonText="Add New Configuration File" actionButtonClick={onAddFile} />
                :
                <NoRecordsMessage topMargin={4} message="No Configuration File" />
            );
            return noRecords;
        }
    }

    return (
        <TabContainer>
            <TabToolbar justify="space-between">
                <Typography variant='h6'>Configuration File</Typography>
                <Stack spacing={2} direction="row" justifyContent="flex-end">
                    {canEdit && (
                        <StyledButton
                            variant={upsertFileVariant}
                            color="primary"
                            startIcon={addEditIcon}
                            data-cy="add-new-Config"
                            onClick={onAddFile}
                        >
                            {upsertFileText}
                        </StyledButton>
                    )}
                    {file && (
                        <Button
                            variant="text"
                            color="primary"
                            startIcon={<DownloadIcon />}
                            onClick={onDownloadClick}
                        >Download</Button>
                    )}
                    {file && (
                        <Button
                            variant="text"
                            color="error"
                            startIcon={<DeleteIcon />}
                            onClick={onDeleteFile}
                            disabled={!canEdit}
                        >
                            Delete</Button>
                    )}
                </Stack>
            </TabToolbar>
            <TabContent>
                {getFileContent()}
            </TabContent>
            <AddEdiXmlConfigDialog
                isOpen={isAddOpen}
                clientId={clientId}
                config={file}
                onClose={onAddDialogClose}
                onSave={onAddDialogSave}
                error={error}
            />
            <DeleteDialog
                isOpen={openDelete}
                heading={'Delete Configuration'}
                message={`Are you sure you want to delete '${file?.fileName}'?`}
                id={file?.id as string}
                onConfirm={handleDeleteFileConfirm}
                onReject={onDeleteDialogClose}
                errorMessage={deleteErrorMessage}
            />
        </TabContainer>
    );
}

export default ConfigurationFile;