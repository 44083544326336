import { useState } from 'react';

import { ButtonGroup, IconButton, Menu, MenuItem, styled } from '@mui/material';

import DownloadIcon from '@mui/icons-material/Download';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { DocumentArchiveModel } from '../../gql-types.generated';
import theme from '../../Theme';

const DownloadIconButton = styled(IconButton)((props) => ({
  padding: '0',
  '&:hover': {
    color: theme.palette.primary.dark,
    fontWeight: 'bold'
  }
}));

const ArrowIconButton = styled(IconButton)((props) => ({
  padding: '0',
  '&:hover': {
    color: theme.palette.primary.dark,
    fontWeight: 'bold'
  }
}));
  
interface DownloadSplitButtonProps {
  onDownloadHtmlClick: () => void;
  onDownloadRawEdiClick: () => void;
  onDownloadXmlClick: () => void;
  onDownloadAcknowledgementClick?: () => void;
}
  
export const DownloadSplitButton: React.FC<DownloadSplitButtonProps> = (props: DownloadSplitButtonProps) => {
    const { onDownloadHtmlClick, onDownloadRawEdiClick, onDownloadXmlClick, onDownloadAcknowledgementClick } = props;
    
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isDownloadMenuOpen = Boolean(anchorEl);

    const onDownloadMenuClick = (event: React.MouseEvent<HTMLButtonElement>, selectedRow?: DocumentArchiveModel) => {
      // this button could be inside of a grid that could have a click event,
      // stopPropagation to prevent that click event from firing
      event.stopPropagation();

      setAnchorEl(event.currentTarget);
    };

    const handleDownloadMenuClose = (event: React.MouseEvent<any>) => {
      // this button could be inside of a grid that could have a click event,
      // stopPropagation to prevent that click event from firing
      event.stopPropagation();
      
      let buttonDataSet = (event.target as HTMLElement).dataset;
      
      if (buttonDataSet.operation === "html") {
        onDownloadHtmlClick();
      }
      else if (buttonDataSet.operation === "raw") {
        onDownloadRawEdiClick();
      }
      else if (buttonDataSet.operation === "xml") {
        onDownloadXmlClick();
      }
      else if (buttonDataSet.operation === "ack") {
        if (onDownloadAcknowledgementClick) {
          onDownloadAcknowledgementClick();
        }
      }
      
      setAnchorEl(null);
    };

    return (
      <>
        <ButtonGroup variant="text" aria-label="download button group">
          <DownloadIconButton
              aria-label="download HTML"
              title="Download HTML"
              color="primary"
              size="medium"
              onClick={onDownloadHtmlClick}
          >
            <DownloadIcon />
          </DownloadIconButton >
          <ArrowIconButton
              id="options-button"
              aria-label="download Options Menu"
              aria-haspopup="menu"
              title="Download Options"
              color="primary"
              size="small"
              onClick={onDownloadMenuClick}
          >
            <ArrowDropDownIcon />
          </ArrowIconButton >
          
        </ButtonGroup>
        <Menu
            id="options-menu"
            anchorEl={anchorEl}
            open={isDownloadMenuOpen}
            onClose={handleDownloadMenuClose}
        >
            <MenuItem
                data-operation="html"
                onClick={handleDownloadMenuClose}
              >
                Download HTML
            </MenuItem>
            <MenuItem
                data-operation="raw"
                onClick={handleDownloadMenuClose}
            >
                Download EDI
            </MenuItem>
            <MenuItem
                data-operation="xml"
                onClick={handleDownloadMenuClose}
            >
                Download XML
            </MenuItem>
            {onDownloadAcknowledgementClick &&
              <MenuItem
                  data-operation="ack"
                  onClick={handleDownloadMenuClose}
              >
                  Download 997
              </MenuItem>
            }
        </Menu> 
      </>
    );
};
  
