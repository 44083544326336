import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { PagingResultModelOfDocumentArchiveModel, GetDocumentArchivesInput, DocumentArchiveListQuery } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_DOCUMENT_ARCHIVE_LIST = gql`
    query documentArchiveList($input: GetDocumentArchivesInput!) {
        documentArchives (input: $input) {
            cursor {
                nextPage
                previousPage
              }
            nodes {
                id
                acknowledgementStatus
                bulkNotify
                client {
                    id
                    name
                }
                controlNumber
                functionalGroupControlNumber
                transactionControlNumber
                createdTime
                direction
                documentDate
                documentType
                fileName
                internalClientCode
                internalPartnerCode
                isAbandoned
                partner {
                    id
                    name
                }
                purchaseOrder
                erpOrderNumber
                documentNumber
                notifiedTime
                receiverId
                senderId
            }
        }
    }
`;

export const queryDocumentArchiveList = async (
    client: ApolloClient<NormalizedCacheObject>,
    filters?: GetDocumentArchivesInput
): Promise<PagingResultModelOfDocumentArchiveModel | undefined> => {
    const { after, limit, clientName, direction, documentType, order, partnerName, controlNumber, functionalGroupControlNumber, erpOrderNumber, documentNumber,
        transactionControlNumber, purchaseOrder, acknowledgementStatuses, fileName, dateFrom, dateTo, wasNotificationSent, isNotificationRequired } = filters ?? {};
    const { data, errors } = await client.query<DocumentArchiveListQuery>({
        variables: {
            input: {
                after,
                limit,
                acknowledgementStatuses,
                clientName: clientName?.trim(),
                controlNumber: controlNumber?.trim(),
                functionalGroupControlNumber: functionalGroupControlNumber?.trim(),
                transactionControlNumber: transactionControlNumber?.trim(),
                purchaseOrder: purchaseOrder?.trim(),
                erpOrderNumber: erpOrderNumber?.trim(),
                documentNumber: documentNumber?.trim(),
                dateFrom,
                dateTo,
                direction,
                documentType: documentType?.trim(),
                fileName: fileName?.trim(),
                order,
                partnerName: partnerName?.trim(),
                wasNotificationSent, 
                isNotificationRequired
            }
        },
        query: QUERY_DOCUMENT_ARCHIVE_LIST,
    });

    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    return data?.documentArchives as PagingResultModelOfDocumentArchiveModel;
}