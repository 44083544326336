import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';

import { PagingResultModelOfClientModel, GetClientByIdQuery, GetClientsInput, Scalars } from '../gql-types.generated';

const QUERY_CLIENT_BY_ID = gql`
query getClientById($input: GetClientsInput!) {
    clients (input: $input){
      nodes {
        id
        internalClientCode
        isaQualifierCode
        localeCode
        clientCode
        name
        defaultCurrencyCode
        primaryClientId
        productionDate
        statusCode
        tenantId
        website
        bulkNotificationTime
        missingAcknowledgementNotificationMode
        erpDocumentFormat
        lastModifiedBy
        lastModifiedByName
        lastModifiedTime
        billingId
        billingStartDate
        billing {
          id
          description
          currencyCode
        }
        addresses {
          addressLine1
          addressLine2
          addressLine3
          addressLine4
          city
          countryCode
          id
          isPrimary
          postalCode
          stateId
          state {
              stateCode
              description
              id
          }
        }
        businessArea {
          name
          description
          id
        }
        notificationFormatter {
          id
          description
          formatterType
          preprocessorType
        }
        errorFormatter {
          id
          description
          formatterType
          preprocessorType
        }
        clientAKAs {
          id
          clientCode
          isaQualifierCode
          isaQualifier {
            isaQualifierCode
            description
          }
        }
        clientNotificationRecipients {
          id
          recipients
        }
        clientContacts {
          id
          clientId
          name
          description
          isActive
          isPrimary
          jobTitle
          addresses {
            addressLine1
            addressLine2
            addressLine3
            addressLine4
            city
            countryCode
            id
            isPrimary
            postalCode
            stateId
            state {
              stateCode
              description
              id
            }
          }
          emails {
            email
            id
            isPrimary
          }
          phones {
            description
            id
            phoneNumber
            phoneTypeCode
          }
          notes {
            createdBy
            createdByName
            createdTime
            header
            id
            lastModifiedBy
            lastModifiedByName
            lastModifiedTime
            note
            parentId
          }
        }
        ediXmlConfig {
          clientId
          createdByName
          createdTime
          description
          fileName
          documentString
          id
        }
        emails {
          email
          id
          isPrimary
        }
        erpProduct {
          id
          name
          isAptean
        }
        clientPartners {
          id
          totalTransactions
          partner {
            partnerCode
            id
            name
            isaQualifierCode
            website
            totalTransactions
            businessArea {
              name
              id
            }
            ediStandards {
              id
              name
            }
          }
        }
        phones {
          phoneNumber
          description
          phoneTypeCode
          id
        }
        notes {
          createdBy
          createdByName
          createdTime
          header
          id
          lastModifiedBy
          lastModifiedByName
          lastModifiedTime
          note
          parentId
        }
        attachments {
            id
            fileName
            isLink
            description
            createdBy
            createdByName
            createdTime
            lastModifiedBy
            lastModifiedByName
            lastModifiedTime
            fileLastModifiedTime
        }
      }
    }
  }
`;

export const queryClientById = async (
  client: ApolloClient<NormalizedCacheObject>,
  id: Scalars['UUID']
): Promise<PagingResultModelOfClientModel | null | undefined> => {
  const { data, errors } = await client.query<GetClientByIdQuery>({
    variables: {
      input: {
        id
      }
    },
    query: QUERY_CLIENT_BY_ID,
  });
  if (errors && errors.length > 0) {
    errors.forEach(error => {
      // Log error details in the console.
      console.error(error);
    });
    // Friendly error to the user.
    throw new Error('An error has occurred during the query.');
  }

  return data?.clients as PagingResultModelOfClientModel;
};